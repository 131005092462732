
$header-height: 96px !default;

.header {
  position: relative;
  z-index: $z-index-header;
  @extend .container;

  @include breakpoint(xs) {
    padding: 24px 0;
  }

  &__inner {
    display: flex;
    margin: -8px 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include breakpoint(s, l) {
      flex-direction: row;
      justify-content: flex-start;
      height: $header-height;
      margin: 0 -8px;
    }
  }

  &__item {
    padding: 8px 0;

    @include breakpoint(s, l) {
      padding: 0 8px;
    }
  }

  &__featured {
    @extend .header__item;

    @include breakpoint(xs) {
      margin-bottom: -24px;
    }
  }

  &__brand {
    display: block;
    line-height: 1.4;
    @extend %t-brand;
  }

  &__badge {
    display: block;
    padding: 0 8px;
    line-height: 32px;
    background: $c-background-shaded;
    border-radius: $default-border-radius;
    @extend %t-text;
  }

  &__flexible-space {

    @include breakpoint(s, l) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
