
* {
  outline: none;
  box-sizing: border-box;
  transition-timing-function: ease;

  // Flex defaults
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  background: $c-background;
  font-size: $default-font-size;
  line-height: $default-line-height;
}

body,
button,
input,
select,
textarea {
  color: $c-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
select,
textarea {
  @extend %t-text;
}

button:disabled,
input:disabled,
select:disabled,
textarea:disabled {
  -webkit-text-fill-color: $c-text;
}

button {
  padding: 0;
  border: none;
  text-align: left;
  cursor: pointer;
  background: none;
  color: inherit;
  font: inherit;
}

input[type='text'],
select,
textarea {
  margin: 0;
  padding: 0;
  width: 100%;
}

input[type='number'] {
  // Hide number controls in firefox
  -moz-appearance: textfield;
}

textarea {
  resize: none;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
  font: inherit;
}

li {
  font: inherit;
}

a {
  text-decoration: inherit;
  color: inherit;
}

img {
  width: 100%;
}
