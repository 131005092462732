
.page {

  &__header {
    padding: 40px 0;
    border-top: 1px solid $c-line;
    border-bottom: 1px solid $c-line;
  }

  &__title {
    @extend %t-h1;
  }

  &__content {
    padding: 40px 0;
    max-width: 848px;
  }
}
