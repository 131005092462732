
.viewer-text {

  &__textarea {
    width: 100%;
    height: 200px;
    padding: 16px;
    border: none;
    overflow: hidden;
    background: transparent;
    @extend %t-monospace;
  }
}
