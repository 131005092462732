
/// Applies containing styles on the given browsers only. Requires JavaScript to
/// identify the current browser and to make the result available through the
/// :root[data-browser="name/major.minor.patch"] attribute.
///
/// @example scss - Disable transitions in IE 11 and Safari 12.1
///   @include browser(ie, 'safari/12.1') {
///     transition: none;
///   }
///
/// @param {Arglist} $browsers Browser identifiers with optional version numbers
@mixin browser($browsers...) {
  // Compose selector for one or more browser identifiers
  $selector: ();
  @each $browser in $browsers {
    @if str-index($browser, '/') == false {
      $browser: "#{$browser}/";
    }
    $selector: append(
      $selector,
      unquote(":root[data-browser^='#{$browser}'] &"),
      $separator: comma
    );
  }

  // Place selector
  #{$selector} {
    @content;
  }
}
