
.field-number {
  $block: &;

  &__field {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
  }

  &__value {
    display: block;
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
  }

  &__input,
  &__display {
    border: none;
    width: 100%;
    height: $field-field-height;
    padding: 0;
    text-align: center;
    @extend %t-field-value;
    color: $c-text;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__input::-webkit-inner-spin-button,
  &__input::-webkit-outer-spin-button {
    // remove number input spinners
    -webkit-appearance: none;
    margin: 0;
  }

  &__display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $c-white;
    pointer-events: none;
    visibility: hidden;
    white-space: nowrap;

    &--enabled {
      visibility: visible;
    }

    #{$block}__input:focus + & {
      visibility: hidden;
    }
  }

  &__display-description {
    display: block;
    margin-left: 8px;
    color: $c-text-detail;

    // The description may overflow
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__btn-step-down,
  &__btn-step-up {
    display: block;
    position: relative;
    width: $field-padding-h + 16px;
    height: $field-field-height;
    border-radius: 12px;
    font-size: 0;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: $field-padding-h + 4px;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 2px;
      background: $c-text-detail;
    }

    &:hover {

      &::before,
      &::after {
        background: $c-primary;
      }
    }
  }

  &__btn-step-down {

    &::before {
      content: none;
    }
  }

  &__btn-step-up {

    &::before,
    &::after {
      left: 12px
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
