
.field-text {

  &__input[type='text'] {
    height: $field-field-height;
    padding: $field-field-padding-v $field-padding-h;
    border: none;
    @extend %t-field-value;
  }
}
