
// Body class to prevent the document from scrolling while modal is open
.modal-visible {
  overflow: hidden;
}

.modal {
  $block: &;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-modal;
  visibility: hidden;
  outline: 0;

  // The modal view depends on transition events of this element
  transition-property: visibility;
  transition-duration: 0.6s;

  &--visible {
    visibility: visible;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $c-black;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.4s;
    transition-delay: 0.2s;

    #{$block}--visible & {
      opacity: 0.4;
      transition-delay: 0s;
    }
  }

  &__scrollable {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
  }

  &__outer {
    display: block;
    position: relative;
    width: auto;
    margin: 0 auto;
    padding: 0 8px;
    transform: translate(0, -140px);
    transition-property: transform;
    transition-duration: 0.4s;
    pointer-events: none;

    #{$block}--visible & {
      transform: none;
    }

    @include each-breakpoint(modal-gutter) {
      max-width: (520px + $value * 2);

      @if $name != xs {
        margin-bottom: $value;
      }
    }

    @include breakpoint(xs) {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &__dialog {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    background: $c-white;
    pointer-events: auto;
    outline: 0;
    overflow: hidden;
    @extend %s-sheet-dialog;
    transition-property: height;
    transition-duration: 0.4s;

    @include breakpoint(s, l) {
      border-radius: 0 0 4px 4px;
    }
  }

  &__header {
    position: relative;
    padding-top: 16px;
    padding-bottom: 16px;
    @include each-breakpoint(modal-gutter) {
      padding-left: $value;
      padding-right: $value;
    }
    @extend %t-h2;
  }

  &__btn-close {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 24px;
    font-size: 0;
    color: $c-text-detail;

    &:hover {
      color: $c-text;
    }

    @include each-breakpoint(modal-gutter) {
      padding-right: $value;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: currentColor;
    }
  }

  &__content {
    @include each-breakpoint(modal-gutter) {
      padding: $value;
    }
    background: $c-background-shaded;
  }
}
