
.footer {
  padding: 32px 0;
  border-top: 2px solid $c-line;
  color: $c-footer-text;

  @include breakpoint(xs, s) {
    text-align: center;
  }

  &__inner {
    max-width: 600px;

    @include breakpoint(xs, s) {
      margin: 0 auto;
    }

    @include breakpoint(m, l) {
      max-width: 896px;
    }
  }

  &__brand {
    display: inline;
    @extend %t-text;
  }

  &__description {
    display: inline;
    @extend %t-text;

    > a {
      @extend %t-text-link;

      &:hover {
        color: $c-text-primary;
      }
    }

    &::before {
      content: '– ';
    }
  }

  &__link-list {
    margin-top: 16px;

    @include breakpoint(xs) {
      margin-top: 24px;
    }
  }
}
