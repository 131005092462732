@charset "UTF-8";
.link-list, .footer__description > a, button,
input,
select,
textarea, .header__badge, .menu__button, .content, .footer__brand, .footer__description, .pipe__description, .modal-library__category-title, .modal-library__brick, .brick__status, .field__message, .field-enum--radio .field-enum__option-label {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.link-list, .footer__description > a {
  font-weight: 500;
  line-height: 1.8;
}

.header__brand {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 32px;
}

.featured__copy, #carbonads .carbon-text, .featured__disclamer, #carbonads .carbon-poweredby {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.pipe__title, .page__title {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.5;
}

.modal__header, .content h2, .content h3, .content h4, .content h5, .content h6 {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
}

.featured__visual-text, .brick__action {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.brick__title-inner {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.field__label {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.field-boolean__toggle, .field-enum--default .field-enum__select, .field-enum--radio .field-enum__option-radio:checked + .field-enum__option-label, .field-number__input, .field-number__display, .field-text__input[type='text'], .field-byte__input[type='text'] {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.viewer-text__textarea {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.featured, #carbonads, .modal-library__brick, .brick, .field__message {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  background: #ffffff;
}

.menu {
  box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  background: #ffffff;
}

.modal__dialog {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  background: #ffffff;
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

* {
  outline: none;
  box-sizing: border-box;
  transition-timing-function: ease;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
  font-size: 16px;
  line-height: 1.15;
}

body,
button,
input,
select,
textarea {
  color: #272727;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:disabled,
input:disabled,
select:disabled,
textarea:disabled {
  -webkit-text-fill-color: #272727;
}

button {
  padding: 0;
  border: none;
  text-align: left;
  cursor: pointer;
  background: none;
  color: inherit;
  font: inherit;
}

input[type='text'],
select,
textarea {
  margin: 0;
  padding: 0;
  width: 100%;
}

input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
  font: inherit;
}

li {
  font: inherit;
}

a {
  text-decoration: inherit;
  color: inherit;
}

img {
  width: 100%;
}

.container, .header {
  padding: 0 16px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .container, .header {
    padding: 0 32px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .container, .header {
    padding: 0 48px;
  }
}

@media screen and (min-width: 1400px) {
  .container, .header {
    padding: 0 64px;
  }
}

.link-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -16px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .link-list {
    justify-content: center;
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .link-list {
    flex-direction: column;
    margin: 0;
    border: 1px solid #e3e8ec;
    border-radius: 2px;
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .link-list__item {
    border-bottom: 1px solid #e3e8ec;
  }
  .link-list__item:last-child {
    border-bottom: none;
  }
}

.link-list__link {
  padding: 0 16px;
}

.link-list__link:hover {
  color: #00a3cc;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .link-list__link {
    display: block;
    padding: 8px 16px;
    text-align: left;
  }
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  position: relative;
  z-index: 10;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .header {
    padding: 24px 0;
  }
}

.header__inner {
  display: flex;
  margin: -8px 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .header__inner {
    flex-direction: row;
    justify-content: flex-start;
    height: 96px;
    margin: 0 -8px;
  }
}

.header__item, .header__featured {
  padding: 8px 0;
}

@media screen and (min-width: 768px) {
  .header__item, .header__featured {
    padding: 0 8px;
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .header__featured {
    margin-bottom: -24px;
  }
}

.header__brand {
  display: block;
  line-height: 1.4;
}

.header__badge {
  display: block;
  padding: 0 8px;
  line-height: 32px;
  background: #f2f4f6;
  border-radius: 2px;
}

@media screen and (min-width: 768px) {
  .header__flexible-space {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.modal-visible {
  overflow: hidden;
}

.modal {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  visibility: hidden;
  outline: 0;
  transition-property: visibility;
  transition-duration: 0.6s;
}

.modal--visible {
  visibility: visible;
}

.modal__backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-delay: 0.2s;
}

.modal--visible .modal__backdrop {
  opacity: 0.4;
  transition-delay: 0s;
}

.modal__scrollable {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.modal__outer {
  display: block;
  position: relative;
  width: auto;
  margin: 0 auto;
  padding: 0 8px;
  transform: translate(0, -140px);
  transition-property: transform;
  transition-duration: 0.4s;
  pointer-events: none;
}

.modal--visible .modal__outer {
  transform: none;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .modal__outer {
    max-width: 552px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .modal__outer {
    max-width: 568px;
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .modal__outer {
    max-width: 584px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1400px) {
  .modal__outer {
    max-width: 584px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .modal__outer {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.modal__dialog {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  pointer-events: auto;
  outline: 0;
  overflow: hidden;
  transition-property: height;
  transition-duration: 0.4s;
}

@media screen and (min-width: 768px) {
  .modal__dialog {
    border-radius: 0 0 4px 4px;
  }
}

.modal__header {
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .modal__header {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .modal__header {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .modal__header {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media screen and (min-width: 1400px) {
  .modal__header {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.modal__btn-close {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 24px;
  font-size: 0;
  color: #90999e;
}

.modal__btn-close:hover {
  color: #272727;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .modal__btn-close {
    padding-right: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .modal__btn-close {
    padding-right: 24px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .modal__btn-close {
    padding-right: 32px;
  }
}

@media screen and (min-width: 1400px) {
  .modal__btn-close {
    padding-right: 32px;
  }
}

.modal__btn-close svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.modal__content {
  background: #f2f4f6;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .modal__content {
    padding: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .modal__content {
    padding: 24px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .modal__content {
    padding: 32px;
  }
}

@media screen and (min-width: 1400px) {
  .modal__content {
    padding: 32px;
  }
}

.menu {
  display: block;
  visibility: hidden;
  background: #ffffff;
  z-index: 10;
  max-height: 56px;
  padding: 8px 0;
  overflow: hidden;
  transition-property: none;
  transition-duration: 0.6s;
  transition-timing-function: linear;
}

.menu__list {
  flex-direction: column;
}

.menu__button {
  display: block;
  padding: 8px 16px;
  width: 100%;
  line-height: 24px;
  color: #272727;
}

.menu__button:hover {
  background: #f2f4f6;
}

.menu--visible {
  transition-property: visibility, max-height;
  visibility: visible;
  max-height: 500px;
}

.featured, #carbonads {
  display: block;
  position: relative;
  overflow: hidden;
  width: 375px;
  margin-bottom: -4px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .featured, #carbonads {
    margin: 0 auto -2px;
  }
}

@media screen and (max-width: 375px) {
  .featured, #carbonads {
    width: 100%;
    border-radius: 0;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .featured, #carbonads {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.featured__content, #carbonads .carbon-wrap {
  display: flex;
  flex-direction: row;
}

.featured__visual, #carbonads .carbon-img {
  display: block;
  position: relative;
  width: 130px;
  height: 100px;
}

.featured__visual-text {
  display: block;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
}

.featured__copy, #carbonads .carbon-text {
  display: block;
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px 14px 0;
  margin-bottom: 28px;
}

.featured__disclamer, #carbonads .carbon-poweredby {
  display: block;
  position: absolute;
  left: 130px;
  bottom: 0;
  right: 0;
  padding: 0 14px 10px;
  color: #90999e;
}

.page__header {
  padding: 40px 0;
  border-top: 1px solid #e3e8ec;
  border-bottom: 1px solid #e3e8ec;
}

.page__content {
  padding: 40px 0;
  max-width: 848px;
}

.content h2, .content h3, .content h4, .content h5, .content h6 {
  margin: 32px 0 16px;
}

.content p {
  margin: 24px 0;
}

.content ol,
.content ul {
  padding-left: 40px;
}

.content ul {
  list-style: disc outside;
}

.content ol {
  list-style: decimal;
}

.content li,
.content li p {
  margin: 8px 0;
}

.content a {
  color: #00b1d1;
}

.content a:hover {
  text-decoration: underline;
}

.content > :first-child {
  margin-top: 0;
}

.content > :last-child {
  margin-bottom: 0;
}

.footer {
  padding: 32px 0;
  border-top: 2px solid #e3e8ec;
  color: #8898aa;
}

@media screen and (min-width: 0) and (max-width: 999px) {
  .footer {
    text-align: center;
  }
}

.footer__inner {
  max-width: 600px;
}

@media screen and (min-width: 0) and (max-width: 999px) {
  .footer__inner {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1000px) {
  .footer__inner {
    max-width: 896px;
  }
}

.footer__brand {
  display: inline;
}

.footer__description {
  display: inline;
}

.footer__description > a:hover {
  color: #00a3cc;
}

.footer__description::before {
  content: '– ';
}

.footer__link-list {
  margin-top: 16px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .footer__link-list {
    margin-top: 24px;
  }
}

.pipe {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media screen and (min-width: 1000px) {
  .pipe {
    min-height: calc(100vh - 96px);
  }
}

.pipe--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.pipe__scrollable {
  position: relative;
  background: #f2f4f6;
}

@media screen and (min-width: 1000px) {
  .pipe__scrollable {
    flex-grow: 1;
    order: 1;
    overflow: hidden;
  }
}

.pipe__scroll-handle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  z-index: 50;
}

@media screen and (min-width: 0) and (max-width: 999px) {
  .pipe__scroll-handle {
    display: none;
  }
}

.pipe__scroll-handle--right {
  left: auto;
  right: 0;
}

.pipe__scroll-handle--disabled {
  display: none;
}

.pipe__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 96px 0;
}

@media screen and (min-width: 1000px) {
  .pipe__content {
    flex-direction: row;
    align-items: flex-start;
    float: left;
    padding: 48px 48px;
    min-width: 100%;
  }
}

@media screen and (min-width: 1000px) and (min-width: 0) and (max-width: 767px) {
  .pipe__content {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 1000px) and (min-width: 768px) and (max-width: 999px) {
  .pipe__content {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media screen and (min-width: 1000px) and (min-width: 1000px) and (max-width: 1399px) {
  .pipe__content {
    padding-left: 48px;
    padding-right: 48px;
  }
}

@media screen and (min-width: 1000px) and (min-width: 1400px) {
  .pipe__content {
    padding-left: 64px;
    padding-right: 64px;
  }
}

.pipe__part-pipe {
  position: relative;
  width: 96px;
  height: 56px;
  background: #00b1d1;
  border-color: #00b1d1;
}

@media screen and (min-width: 1000px) {
  .pipe__part-pipe {
    width: 56px;
    height: 96px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.pipe__part-pipe:first-child::after, .pipe__part-pipe:last-child::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  border-width: 24px 48px 0 48px;
  border-style: solid;
  border-color: inherit;
}

@media screen and (min-width: 1000px) {
  .pipe__part-pipe:first-child::after, .pipe__part-pipe:last-child::after {
    top: 0;
    bottom: 0;
    border-width: 48px 0 48px 24px;
  }
}

.pipe__part-pipe:first-child::after {
  top: -24px;
  border-top-color: transparent;
}

@media screen and (min-width: 1000px) {
  .pipe__part-pipe:first-child::after {
    top: auto;
    border-top-color: inherit;
    left: -24px;
    border-left-color: transparent;
  }
}

.pipe__part-pipe:last-child::after {
  bottom: -24px;
  border-left-color: transparent;
  border-right-color: transparent;
}

@media screen and (min-width: 1000px) {
  .pipe__part-pipe:last-child::after {
    bottom: 0;
    left: auto;
    right: -24px;
    border-top-color: transparent;
    border-right-color: inherit;
    border-bottom-color: transparent;
    border-left-color: inherit;
  }
}

.pipe__part-collapsed {
  display: flex;
  flex-direction: column;
  width: 96px;
  padding: 4px 0;
}

@media screen and (min-width: 1000px) {
  .pipe__part-collapsed {
    flex-direction: row;
    height: 96px;
    width: auto;
    margin: 8px 0;
    padding: 0 4px;
  }
}

.pipe__part-collapsed-fold {
  width: 96px;
  height: 8px;
  margin: 4px 0;
  background: #00b1d1;
}

@media screen and (min-width: 1000px) {
  .pipe__part-collapsed-fold {
    width: 8px;
    height: 96px;
    margin: 0 4px;
  }
}

.pipe__part-brick {
  width: 100%;
  min-width: 320px;
  max-width: 448px;
  flex-grow: 1;
}

@media screen and (min-width: 1000px) {
  .pipe__part-brick {
    max-width: 576px;
  }
}

@media screen and (min-width: 1000px) {
  .pipe__part-brick--encoder {
    max-width: 368px;
  }
}

@media screen and (min-width: 0) and (max-width: 999px) {
  :root[data-browser^='ie/11'] .pipe__part-brick {
    position: relative;
    z-index: 1;
    margin: -3px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .pipe__part-brick {
    width: 448px;
  }
}

@media screen and (min-width: 1000px) {
  .pipe__part-brick {
    width: auto;
    flex-basis: 0;
  }
  :root[data-browser^='ie/11'] .pipe__part-brick {
    flex-basis: 360px;
    flex-grow: 0;
  }
}

.pipe__header {
  order: 2;
  padding-top: 36px;
  padding-bottom: 32px;
}

@media screen and (min-width: 0) and (max-width: 999px) {
  .pipe__header {
    text-align: center;
  }
}

.pipe__title, .pipe__description, .pipe__link-list {
  max-width: 600px;
}

@media screen and (min-width: 0) and (max-width: 999px) {
  .pipe__title, .pipe__description, .pipe__link-list {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1000px) {
  .pipe__title, .pipe__description, .pipe__link-list {
    max-width: 896px;
  }
}

.pipe__title {
  display: block;
  padding: 0 0 14px;
}

.pipe__link-list {
  margin-top: 16px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .pipe__link-list {
    margin-top: 24px;
  }
}

.pipe__btn-add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border: 2px solid #ffffff;
  border-radius: 13px;
  text-indent: -1000px;
  z-index: 1;
  opacity: .4;
  overflow: hidden;
}

.pipe__btn-add::before, .pipe__btn-add::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 2px;
  background: #ffffff;
}

.pipe__btn-add::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.pipe__part-pipe:hover .pipe__btn-add {
  opacity: 1;
}

.pipe--dragging .pipe__btn-add {
  opacity: 0;
}

.pipe__drop-handle {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 11px;
  background: #f2f4f6;
  transform: translate(0, -50%) translate(0, -0.5px);
  z-index: 1;
  visibility: hidden;
}

@media screen and (min-width: 1000px) {
  .pipe__drop-handle {
    top: 0;
    left: 50%;
    bottom: 0;
    right: auto;
    height: auto;
    width: 11px;
    transform: translate(-50%, 0) translate(-0.5px, 0);
  }
}

.pipe__drop-handle::before, .pipe__drop-handle::after {
  content: '';
  display: block;
  position: absolute;
}

.pipe__drop-handle::before {
  background: #00b1d1;
}

@media screen and (min-width: 0) and (max-width: 999px) {
  .pipe__drop-handle::before {
    top: 4px;
    left: -16px;
    right: -14px;
    height: 3px;
  }
}

@media screen and (min-width: 1000px) {
  .pipe__drop-handle::before {
    left: 4px;
    top: -16px;
    bottom: -14px;
    width: 3px;
  }
}

.pipe__drop-handle::after {
  width: 7px;
  height: 7px;
  border-radius: 16px;
  border: 3px solid #00b1d1;
  background: #f2f4f6;
}

@media screen and (min-width: 0) and (max-width: 999px) {
  .pipe__drop-handle::after {
    top: -1px;
    left: -24px;
  }
}

@media screen and (min-width: 1000px) {
  .pipe__drop-handle::after {
    left: -1px;
    top: -24px;
  }
}

.pipe__part-pipe--dragging .pipe__drop-handle {
  visibility: visible;
}

.modal-library__categories {
  margin: -12px 0;
}

.modal-library__category {
  padding: 12px 0;
}

.modal-library__category-title {
  display: block;
  padding-bottom: 10px;
  color: #90999e;
}

.modal-library__bricks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .modal-library__bricks {
    flex-direction: column;
  }
}

.modal-library__brick {
  display: block;
  margin: 4px;
  padding: 0 12px;
  line-height: 40px;
}

.modal-library__brick::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 8px 1px 0;
  border-radius: 4px;
  background: #00b1d1;
}

.modal-library__brick--encoder::before {
  background: #ff9900;
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.brick {
  display: flex;
  position: relative;
  flex-direction: column;
  user-select: none;
}

@media screen and (max-width: 448px) {
  .brick {
    border-radius: 0;
  }
}

.brick__header {
  position: relative;
  padding: 16px;
  height: 104px;
  border-bottom: 1px solid #e3e8ec;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.pipe--dragging .brick__header {
  cursor: inherit;
}

.brick__btn-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 48px;
  color: #90999e;
}

.brick__btn-menu:hover {
  color: #272727;
}

.brick__btn-menu svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.brick__menu {
  position: absolute;
  top: -8px;
  right: -8px;
  min-width: 160px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .brick__menu {
    right: 8px;
  }
}

.brick__actions {
  display: flex;
  flex-direction: row;
  margin: 0 -6px;
}

.brick__action {
  display: block;
  padding: 0 6px;
  color: #90999e;
}

.brick__action--active {
  color: inherit;
}

.brick__title {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  max-width: 100%;
}

.brick__title-inner {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.brick__title-caret {
  margin-top: 4px;
  margin-left: 4px;
  pointer-events: none;
}

.brick__title-caret svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.brick__content {
  line-height: 0;
}

.brick__status {
  padding: 13px 16px;
  min-height: 48px;
  word-break: break-word;
  color: #90999e;
  transition-property: color;
}

.brick__status--hidden {
  display: none;
}

.brick__status--error {
  color: #ff6940;
}

.brick__status--forward, .brick__status--backward {
  transition-delay: .5s;
  transition-duration: .5s;
}

.brick__status--flash {
  color: inherit;
  transition-delay: 0s;
  transition-duration: 0s;
}

.brick__status-icon {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.brick__status-icon svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

@media screen and (min-width: 0) and (max-width: 999px) {
  .brick__status--forward .brick__status-icon svg,
  .brick__status--backward .brick__status-icon svg {
    transform: rotate(90deg);
  }
}

.brick__status--forward .brick__status-icon svg {
  transform: rotate(180deg);
}

@media screen and (min-width: 0) and (max-width: 999px) {
  .brick__status--forward .brick__status-icon svg {
    transform: rotate(270deg);
  }
}

.brick__status-message {
  display: inline;
  user-select: auto;
}

.viewer {
  color: #00b1d1;
}

.encoder {
  color: #ff9900;
}

.viewer-text__textarea {
  width: 100%;
  height: 200px;
  padding: 16px;
  border: none;
  overflow: hidden;
  background: transparent;
}

.viewer-punched-tape__canvas {
  width: 100%;
  min-height: 200px;
}

.viewer-punched-tape__mark {
  fill: #90999e;
}

.viewer-punched-tape__tape {
  fill: #90999e;
}

.viewer-punched-tape__hole {
  fill: #ffffff;
}

.field {
  display: flex;
  position: relative;
  flex-direction: column;
  border-bottom: 1px solid #e3e8ec;
  border-left: 1px solid #e3e8ec;
  min-height: 57px;
  width: 100%;
}

.field__label {
  display: block;
  padding: 12px 12px 0 16px;
  border-right: 1px solid #e3e8ec;
  color: #90999e;
  align-self: flex-start;
  user-select: auto;
}

.field__field, .field-enum--radio .field-enum__field {
  position: relative;
  margin: 12px 16px;
  min-height: 22px;
  user-select: auto;
}

.field__message {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  padding: 9px 12px 8px;
  border-radius: 2px;
  transform: translate(-50%, 100%);
  z-index: 10;
  background: #ff6940;
  border-color: #ff6940;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.2s;
  word-break: break-word;
}

.field--focus .field__message {
  opacity: 1;
  visibility: visible;
}

.field__message::before {
  content: '';
  display: block;
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translate(-50%, 0);
  border-width: 7px;
  border-style: solid;
  border-color: transparent;
  border-top-width: 0;
  border-bottom-color: inherit;
}

.field[data-width="1"] {
  width: 8.33333%;
}

.field[data-width="1"] .field__label {
  border: none;
}

.field[data-width="2"] {
  width: 16.66667%;
}

.field[data-width="2"] .field__label {
  border: none;
}

.field[data-width="3"] {
  width: 25%;
}

.field[data-width="3"] .field__label {
  border: none;
}

.field[data-width="4"] {
  width: 33.33333%;
}

.field[data-width="4"] .field__label {
  border: none;
}

.field[data-width="5"] {
  width: 41.66667%;
}

.field[data-width="5"] .field__label {
  border: none;
}

.field[data-width="6"] {
  width: 50%;
}

.field[data-width="6"] .field__label {
  border: none;
}

.field[data-width="7"] {
  width: 58.33333%;
}

.field[data-width="7"] .field__label {
  border: none;
}

.field[data-width="8"] {
  width: 66.66667%;
}

.field[data-width="9"] {
  width: 75%;
}

.field[data-width="10"] {
  width: 83.33333%;
}

.field[data-width="11"] {
  width: 91.66667%;
}

.field--first {
  border-left: none;
}

.field--invalid::before {
  content: '';
  display: block;
  width: 3px;
  background: #ff6940;
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
}

.field--invalid .field__label {
  color: #ff6940;
}

.field-boolean__input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
}

.field-boolean__toggle {
  display: block;
  user-select: none;
  cursor: pointer;
  line-height: 22px;
  color: #90999e;
}

.field-boolean__choice {
  display: inline-block;
  margin-right: 8px;
}

.field-boolean__choice:last-child {
  color: #272727;
}

.field-boolean__input:checked + .field-boolean__toggle .field-boolean__choice:first-child {
  color: #272727;
}

.field-boolean__input:checked + .field-boolean__toggle .field-boolean__choice:last-child {
  color: inherit;
}

.field-enum--default .field-enum__field {
  position: relative;
}

.field-enum--default .field-enum__field::before, .field-enum--default .field-enum__field::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 14px;
  height: 2px;
  width: 8px;
  transform: translate(-0.5px, 2px) rotate(-45deg);
  transform-origin: left center;
  background: #90999e;
  pointer-events: none;
}

.field-enum--default .field-enum__field::after {
  transform: translate(0.5px, 2px) rotate(-135deg);
}

.field-enum--default .field-enum__select {
  border: none;
  padding: 12px 32px 12px 16px;
  line-height: 22px;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

:root[data-browser^='ie/11'] .field-enum--default .field-enum__select::-ms-expand {
  display: none;
}

.field-enum--radio .field-enum__option {
  position: relative;
}

.field-enum--radio .field-enum__option-radio {
  position: absolute;
  top: 6px;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.field-enum--radio .field-enum__option-label {
  display: block;
  padding: 4px 0 4px 24px;
  line-height: 22px;
  color: #90999e;
  user-select: none;
  cursor: pointer;
}

.field-enum--radio .field-enum__option-label::before {
  content: '';
  position: absolute;
  top: 6px;
  left: 0;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border: 2px solid #90999e;
  border-radius: 8px;
  pointer-events: none;
}

.field-enum--radio .field-enum__option-label::after {
  content: '';
  position: absolute;
  top: 11px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 8px;
  pointer-events: none;
}

.field-enum--radio .field-enum__option-radio:checked + .field-enum__option-label {
  color: #272727;
}

.field-enum--radio .field-enum__option-radio:checked + .field-enum__option-label::before {
  border-color: #272727;
}

.field-enum--radio .field-enum__option-radio:checked + .field-enum__option-label::after {
  background: #272727;
}

.field-number__field {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
}

.field-number__value {
  display: block;
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
}

.field-number__input, .field-number__display {
  border: none;
  width: 100%;
  height: 46px;
  padding: 0;
  text-align: center;
  color: #272727;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.field-number__input::-webkit-inner-spin-button, .field-number__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.field-number__display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  pointer-events: none;
  visibility: hidden;
  white-space: nowrap;
}

.field-number__display--enabled {
  visibility: visible;
}

.field-number__input:focus + .field-number__display {
  visibility: hidden;
}

.field-number__display-description {
  display: block;
  margin-left: 8px;
  color: #90999e;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.field-number__btn-step-down, .field-number__btn-step-up {
  display: block;
  position: relative;
  width: 32px;
  height: 46px;
  border-radius: 12px;
  font-size: 0;
}

.field-number__btn-step-down::before, .field-number__btn-step-down::after, .field-number__btn-step-up::before, .field-number__btn-step-up::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 2px;
  background: #90999e;
}

.field-number__btn-step-down:hover::before, .field-number__btn-step-down:hover::after, .field-number__btn-step-up:hover::before, .field-number__btn-step-up:hover::after {
  background: #00b1d1;
}

.field-number__btn-step-down::before {
  content: none;
}

.field-number__btn-step-up::before, .field-number__btn-step-up::after {
  left: 12px;
}

.field-number__btn-step-up::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.field-text__input[type='text'] {
  height: 46px;
  padding: 12px 16px;
  border: none;
}

.field-byte__input[type='text'] {
  height: 46px;
  padding: 12px 16px;
  border: none;
}
