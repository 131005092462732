
/// Builds a media query between the given breakpoint identifiers.
/// @param {String} $start Start breakpoint
/// @param {String|Boolean} $end End breakpoint (optional)
@mixin breakpoint($from, $to: false) {
  $breakpoint-names: map-keys($breakpoints);
  $query: '';
  $min: false;
  $max: false;
  $start: index($breakpoint-names, $from);
  $end: length($breakpoint-names);

  @if not $to {
    $min: map-get(map-get($breakpoints, $from), min);
    $max: map-get(map-get($breakpoints, $from), max);
  } @else {
    $min: map-get(map-get($breakpoints, $from), min);
    $max: map-get(map-get($breakpoints, $to), max);
    $end: index($breakpoint-names, $to);
  }

  @if $min and not $max {
    $query: 'screen and (min-width: #{$min})';
  } @else if $max and not $min {
    $query: 'screen and (max-width: #{$max})';
  } @else {
    $query: 'screen and (min-width: #{$min}) and (max-width: #{$max})';
  }

  @media #{$query} {
    @content;
  }
}
