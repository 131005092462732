
.field {
  $block: &;
  display: flex;
  position: relative;
  flex-direction: column;
  border-bottom: 1px solid $c-line;
  border-left: 1px solid $c-line;
  min-height: 57px;
  width: 100%;

  &__label {
    display: block;
    padding:
      $field-label-padding-v $field-label-padding-v
      0 $field-padding-h;
    border-right: 1px solid $c-line;
    @extend %t-field-label;
    color: $c-field-label;
    align-self: flex-start;
    user-select: auto;
  }

  &__field {
    position: relative;
    margin: $field-field-padding-v $field-padding-h;
    min-height: $field-field-height - $field-field-padding-v * 2;
    user-select: auto;
  }

  &__message {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    padding: 9px 12px 8px;
    border-radius: $default-border-radius;
    transform: translate(-50%, 100%);
    z-index: $z-index-field-message;
    background: $c-red;
    border-color: $c-red;
    @extend %s-sheet;
    @extend %t-text;
    color: $c-white;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;
    word-break: break-word;

    #{$block}--focus & {
      opacity: 1;
      visibility: visible;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translate(-50%, 0);
      border-width: 7px;
      border-style: solid;
      border-color: transparent;
      border-top-width: 0;
      border-bottom-color: inherit;
    }
  }

  // Width modifier
  @for $cols from 1 through 11 {
    &[data-width="#{$cols}"] {
      width: 100% * ($cols / 12);

      @if $cols < 8 {
        // Remove label border for small field cells
        #{$block}__label {
          border: none;
        }
      }
    }
  }

  &--first {

    // Modifier applied on first field in each row
    border-left: none;
  }

  &--invalid {

    &::before {
      content: '';
      display: block;
      width: 3px;
      background: $c-line-error;
      position: absolute;
      top: 4px;
      left: 4px;
      bottom: 4px;
    }

    #{$block}__label {
      color: $c-text-error;
    }
  }
}
