
%t-text {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

%t-text-link {
  @extend %t-text;
  font-weight: 500;
  line-height: 1.8;
}

%t-brand {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 32px;
}

%t-featured-text {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

%t-h1 {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.5;
}

%t-h2 {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
}

%t-pipe-headline {
  @extend %t-h1;
}

%t-brick-action {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

%t-brick-headline {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

%t-field-label {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

%t-field-value {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 16px;
}

%t-monospace {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}
