
.container {
  padding: 0 map-get(map-get($breakpoints, xs), gutter);

  @include breakpoint(s) {
    padding: 0 map-get(map-get($breakpoints, s), gutter);
  }

  @include breakpoint(m) {
    padding: 0 map-get(map-get($breakpoints, m), gutter);
  }

  @include breakpoint(l) {
    padding: 0 map-get(map-get($breakpoints, l), gutter);
  }
}
